import { useState, useEffect } from 'react'
import { useTitle } from '../../hooks/useTitle'
import api from '../../services/api'
import formatMoney from '../../util/formatMoney'
import getDateStr from '../../util/getDateStr'
import * as S from './styles'
import Loading from '../../commons/Loading'
import Breadcrumb from '../../components/Breadcrumb'
import LoadingBackground from '../../components/LoadingBackground'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import { useAuth } from '../../context/AuthContext'
import Paginator from '../../components/Paginator'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleDown} from '@fortawesome/free-regular-svg-icons'
import SearchableSelect from 'react-select'
import { themeForSearchableSelect } from '../../util/themeForSearchableSelect'

const Financial = () => {
  const { user } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [accountStatusOptions, setAccountStatusOptions] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [copiedRowId, setCopiedRowId] = useState(null);
  const [filteredStatus, setFilteredStatus] = useState(null);
  const [filteredStatusValue, setFilteredStatusValue] = useState(null);
  const [noAccountMessage, setNoAccountMessage] = useState(null);

  const getFinancialInformation = async (status) => {
    setIsLoading(true)
    try {
      const checkStatus = status ? `&status=${status}` : '';
      const response = await api.get(`api/v2/AccountReceivable?ClientId=${user?.clientId}&Ecommerce=true&Page=${currentPage}&Size=10${checkStatus}`);
      const responseData = response?.data?.response?.data;

      if (status && responseData?.length === 0) {
        setNoAccountMessage(`Não há registros com o status ${status}`);
      } else {
        setNoAccountMessage('Você não possui histórico financeiro para visualização.');
      }

      setAccounts(responseData);
      setTotalPages(response?.data?.response?.totalPages);
    } catch (error) {
      console.log('error:', error);
      toast.error('Falha ao obter Informações Financeiras.')
    } finally {
      setIsLoading(false)
      ReactTooltip.rebuild()
    }
  }

  const getAccountStatus = async () => {
    setIsLoading(true)
    try {
      const response = await api.get(`api/v2/AccountReceivable/Status`);
      const responseData = response?.data?.response?.data;
 
      const tmpAccountStatusOptions = responseData?.length > 0
      ? [
          { value: '', label: '' },
          ...responseData.map(account => ({
            value: account,
            label: account,
          })),
        ]
      : [];

      setAccountStatusOptions(tmpAccountStatusOptions);
    } catch (error) {
      console.log('error:', error);
      toast.error('Falha ao obter Status das Informações Financeiras.')
    } finally {
      setIsLoading(false)
    }
  }

  const handlePageClick = (e) => {
    window.scrollTo({ top: 0 })
    setIsLoading(true)
    setCurrentPage(e.selected + 1)
  }

  const handleCopyLink = async (id, link) => {
    toast.dismiss();
    try {
      await navigator.clipboard.writeText(link);
      setCopiedRowId(id);
      toast.success('Link copiado!', {
        autoClose: 1500,
        theme: "light",
      });
    } catch (error) {
      console.error("Falha ao copiar:", error);
      setCopiedRowId(null);
      toast.error('Falha ao copiar link', {
        autoClose: 1500,
        theme: "light",
      });
    };
  }

  const handleSelectedFilterStatus = (selectedOpt) => {
    const value = selectedOpt.value;
    setFilteredStatusValue(value ? value : null)
    setFilteredStatus(selectedOpt ? selectedOpt : null)
    setCurrentPage(1)
    getFinancialInformation(value)
  }

  useEffect(() => {
    getAccountStatus()
  }, [])

  useEffect(() => {
    getFinancialInformation(filteredStatusValue)
  }, [currentPage])

  useTitle('Informações Financeiras')

  return (
    <S.FinancialContainer>
      <Breadcrumb current='Informações Financeiras' />
      {isLoading
        ? ( <LoadingBackground /> )
        : (
          <>
            <S.FinancialHeader>
              <S.FinancialTitle>Informações Financeiras</S.FinancialTitle>
              <S.FilterSelectWrapper>
                <SearchableSelect
                  name='select-status'
                  value={filteredStatus}
                  placeholder='Filtrar por status...'
                  noOptionsMessage={() => 'Sem resultados.'}
                  defaultValue=''
                  styles={themeForSearchableSelect()}
                  options={accountStatusOptions}
                  onChange={(selectedOption) => handleSelectedFilterStatus(selectedOption)}
                />
              </S.FilterSelectWrapper>
            </S.FinancialHeader>
            {/* Tabela Web */}
            <S.FinancialTable>
              <S.FinancialTableHeader>
                <S.FinancialTableItem>Data de emissão</S.FinancialTableItem>
                <S.FinancialTableItem>Data de vencimento</S.FinancialTableItem>
                <S.FinancialTableItem>Documento</S.FinancialTableItem>
                <S.FinancialTableItem>Valor</S.FinancialTableItem>
                <S.FinancialTableItem>Status</S.FinancialTableItem>
                <S.FinancialTableItem>Visualizar boleto</S.FinancialTableItem>
              </S.FinancialTableHeader>
              {accounts.length > 0 ? accounts.map(account => (
                <S.FinancialTableRow key={account.id}>
                  <S.FinancialTableItem>{getDateStr(account.dataEmissao, true)}</S.FinancialTableItem>
                  <S.FinancialTableItem>{getDateStr(account.dataVencimento, true)}</S.FinancialTableItem>
                  <S.FinancialTableItem>{account.doc}</S.FinancialTableItem>
                  <S.FinancialTableItem>{formatMoney(account.value)}</S.FinancialTableItem>
                  <S.FinancialTableItem>{account.status}</S.FinancialTableItem>
                  <S.FinancialTableItem>
                    <S.FinancialTableItemLink href={account.linkBoleto || '#'} target="_blank" rel="noopener noreferrer" disabled={!account.linkBoleto}>
                      Baixar
                    </S.FinancialTableItemLink>
                  </S.FinancialTableItem>
                  <S.FinancialTableItem>
                    <S.FinancialButton
                      data-tip={
                        account.linkBoleto
                          ? copiedRowId === account.id
                            ? 'Link copiado!'
                            : 'Copiar link'
                          : 'Link indisponível'
                      }
                      onClick={account.linkBoleto ? () => handleCopyLink(account.id, account.linkBoleto) : undefined}
                      disabled={!account.linkBoleto}
                    >
                      <S.StyledFontAwesomeIcon icon={faLink} />
                    </S.FinancialButton>
                  </S.FinancialTableItem>
                </S.FinancialTableRow>
              ))
            : <S.FinancialTableRow>
                <S.FinancialNoContent>
                  {noAccountMessage}
                </S.FinancialNoContent>
              </S.FinancialTableRow>}
            </S.FinancialTable>
            {/* Tabela Mobile */}
            <S.FinancialTableMobile>
              {accounts.length > 0 
                ? accounts.map(account => (
                  <S.FinancialTableMobileContent key={account.id}>
                    <S.FinancialTableHeader>
                      <S.FinancialTableItemMobileFull>Documento</S.FinancialTableItemMobileFull>
                      <S.FinancialTableRow>
                        <S.FinancialTableItemMobileFull>{account.doc}</S.FinancialTableItemMobileFull>
                      </S.FinancialTableRow>
                    </S.FinancialTableHeader>
                    <S.FinancialTableHeader>
                      <S.FinancialTableItem>Data de emissão</S.FinancialTableItem>
                      <S.FinancialTableItem>Data de vencimento</S.FinancialTableItem>
                      <S.FinancialTableRow>
                        <S.FinancialTableItem>{getDateStr(account.dataEmissao, true)}</S.FinancialTableItem>
                        <S.FinancialTableItem>{getDateStr(account.dataVencimento, true)}</S.FinancialTableItem>
                      </S.FinancialTableRow>
                    </S.FinancialTableHeader>
                    <S.FinancialTableHeader>
                      <S.FinancialTableItem>Valor</S.FinancialTableItem>
                      <S.FinancialTableItem>Status</S.FinancialTableItem>
                      <S.FinancialTableRow>
                        <S.FinancialTableItem>{formatMoney(account.value)}</S.FinancialTableItem>
                        <S.FinancialTableItem>{account.status}</S.FinancialTableItem>
                        <S.FinancialTableItem>
                          <S.FinancialTableItemLink href={account.linkBoleto || '#'} target="_blank" rel="noopener noreferrer" disabled={!account.linkBoleto}>
                          <S.StyledFontAwesomeIcon icon={faArrowAltCircleDown} /> Baixar
                          </S.FinancialTableItemLink>
                          <S.FinancialButton
                            data-tip={
                              account.linkBoleto
                                ? copiedRowId === account.id
                                  ? 'Link copiado!'
                                  : 'Copiar link'
                                : 'Link indisponível'
                            }
                            onClick={account.linkBoleto ? () => handleCopyLink(account.id, account.linkBoleto) : undefined}
                            disabled={!account.linkBoleto}
                          >
                            <S.StyledFontAwesomeIcon icon={faLink} /> Copiar Link
                          </S.FinancialButton>
                        </S.FinancialTableItem>
                      </S.FinancialTableRow>
                    </S.FinancialTableHeader>

                  </S.FinancialTableMobileContent>
                ))
                : 
                  <S.FinancialNoContent>
                    {noAccountMessage}
                  </S.FinancialNoContent>
              }
            </S.FinancialTableMobile>
            {!isLoading && totalPages > 1 && <Paginator totalPages={totalPages} currentPage={currentPage} handlePageClick={handlePageClick} />}
          </>
        )
      }
    </S.FinancialContainer>
  )
}

export default Financial