import api from '../../services/api'
import * as S from './styles'
import { useState, useEffect, useCallback } from 'react'
import { useSearch } from '../../context/SearchContext'
import { useOnOutsideClick } from '../../hooks/useOnOutsideClick'
import {  faChevronDown, faChevronUp, faFilter } from '@fortawesome/free-solid-svg-icons'
import LoadingBackground from '../../components/LoadingBackground'
import Filter from '../Filter'

const HorizontalMenu = ({ isMobile }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [family, setFamily] = useState([])
  const [subcategory, setSubcategory] = useState([])
  const [collection, setCollection] = useState([])
  const [collectionVariations, setCollectionVariations] = useState([])
  const [menuOptions, setMenuOptions] = useState([])
  const [allCategoriesOption, setAllCategoriesOption] = useState([])
  const [showMainMenuSubcategories, setShowMainMenuSubcategories] = useState(null)
  const [showSubcategories, setShowSubcategories] = useState(null)
  const [showVariations, setShowVariations] = useState(null)
  const [isCategoryOpen, setIsCategoryOpen] = useState(false)
  const [showVariationsMobile, setShowVariationsMobile] = useState(null)
  const [showSubcategoriesMobile, setShowSubcategoriesMobile] = useState(null)
  const [showCollectionsMobile, setShowCollectionsMobile] = useState(false)
  const [showAllCategoriesMobile, setShowAllCategoriesMobile] = useState(false)
  const [familySelectedMobile, setFamilySelectedMobile] = useState(false)
  const [hovered, setHovered] = useState(null)
  const { setCurrentPage } = useSearch()
  const { innerBorderRef } = useOnOutsideClick(() => {setShowMainMenuSubcategories(null), setHovered(null), setShowSubcategories(null), setShowVariations(null)})

  const customer = JSON.parse(localStorage.getItem('DAuth:customer'))
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
  const menuHorizontalCategoriesIds = customer.environmentConfiguration.menuHorizontalCategoriesIds
  const customHeaderColor = customer.environmentConfiguration.customHeaderColor
  const portalMenuType = customer ? customer?.environmentConfiguration?.portalMenuType : customerInfo.portalMenuType
  const changeHeaderColor= customer?.environmentConfiguration?.customHeaderColor

  const fetchData = async () => {
    setIsLoading(true);
  
    try {
      // Faz todas as requisições ao mesmo tempo
      const [familyResponse, subcategoryResponse, collectionResponse] = await Promise.all([
        api.get('/api/v2/Category?OnlyB2B=true&Size=500'),
        api.get('/api/v2/SubCategory?OnlyB2B=true&Size=500'),
        api.get('/api/v2/collection?size=150&OnlyB2B=true')
      ]);
  
      // Atualiza os estados com os resultados
      setFamily(familyResponse.data.response.data);
      setSubcategory(subcategoryResponse.data.response.data);
      setCollection(collectionResponse.data.response.data);
  
      // Após obter as coleções, busca as variações
      const tmpVariations = await Promise.all(
        collectionResponse.data.response.data.map(async (item) => {
          const variationResponse = await api.get(`/api/v2/collection/${item?.id}/Variations`);
          return {
            collectionId: item.id,
            variations: variationResponse.data.response.data,
          };
        })
      );
      
      setCollectionVariations(tmpVariations);
    } catch (error) {
      console.error("Erro ao buscar dados", error);
    } finally {
      // Desativa o loading após todas as requisições
      setIsLoading(false);
    }
  };

  const mountMenuOptions = useCallback(() => {
    const familyWithSubcategory = family.map(item => {
      const tmpSubcategory = []
      subcategory.map(sub => {
        if (sub.categoryId === item.id) {
          tmpSubcategory.push(sub)
        }
      })
      item.subcategory = tmpSubcategory
      return item
    })

    const tmpMenu = familyWithSubcategory.filter(item => menuHorizontalCategoriesIds.includes(item.id));
    const familyWithoutTmpMenu = familyWithSubcategory.filter(item => !menuHorizontalCategoriesIds.includes(item.id));
    // Ordena as opções do menu para ficarem coerentes com a ordem que vem do endpoint
    const sortedMenu = tmpMenu.sort((a, b) => menuHorizontalCategoriesIds.indexOf(a.id) - menuHorizontalCategoriesIds.indexOf(b.id));

    setMenuOptions(sortedMenu)
    setAllCategoriesOption(familyWithoutTmpMenu)
  }, [family, subcategory])

  const handleResetPage = () => {
    setCurrentPage(1)

    // Fecha os menus abertos abaixo
    setShowMainMenuSubcategories(null)
    setShowSubcategories(null)
    setShowVariations(null)
    // Limpa os menus Mobile abaixo
    setIsCategoryOpen(false)
    setShowVariationsMobile(null)
    setShowSubcategoriesMobile(null)
    setShowCollectionsMobile(false)
    setShowAllCategoriesMobile(false)
    setFamilySelectedMobile(false)
  }

  const handleOpenCategory = () => {
    setIsCategoryOpen(!isCategoryOpen)
  }

  const verifyMobileMenuLabel = () => {
    let labelMobileMenu = ''
    if (portalMenuType !== 2) {
      isCategoryOpen ? labelMobileMenu = 'Fechar Categorias' : labelMobileMenu = 'Categorias de Produtos'
    } else {
      isCategoryOpen ? labelMobileMenu = 'Fechar Coleções' : labelMobileMenu = 'Coleções de Produtos'
    }
    return labelMobileMenu
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    mountMenuOptions()
  }, [subcategory, family])
  
  return (
    <>
      {isLoading ? (
        <LoadingBackground />
      ) : (
        isMobile ? 
        <>
          <Filter isMobile category={family} />
          <S.HorizontalMenuMobile>
            <S.HorizontalMenuMobileBtn $changeHeaderColor={changeHeaderColor} isCategoryOpen={isCategoryOpen} onClick={handleOpenCategory} ref={innerBorderRef}>
              <S.HorizontalMenuMobileBtnIcon type={isCategoryOpen ? 'x' : 'bulletList'} />
              {verifyMobileMenuLabel()}
            </S.HorizontalMenuMobileBtn>
            <S.HorizontalMenuMobileCntDrop isCategoryOpen={isCategoryOpen}>
              {(portalMenuType === 2 || portalMenuType === 3) && collection.length > 0 &&
                <>
                  <S.HorizontalMenuMobileTitle onClick={() => setShowCollectionsMobile(showCollectionsMobile === true ? false : true)}>
                    Coleções
                    {showCollectionsMobile ?  <S.StyledFontAwesomeIcon icon={faChevronUp} /> :  <S.StyledFontAwesomeIcon icon={faChevronDown} />}
                  </S.HorizontalMenuMobileTitle>
                    {showCollectionsMobile && (
                      <>
                        <S.HorizontalMenuMobileList showContent={showCollectionsMobile}>
                          {collection.map((collection) => (
                              <S.HorizontalMenuMobileItem key={collection.id}>
                                <S.HorizontalMenuMobileItemLink to='#' state={{ collectionId: collection.id }} onClick={() => {setShowVariationsMobile(showVariationsMobile === collection.id ? null : collection.id); setIsCategoryOpen(true)}}>
                                  <S.HorizontalMenuMobileItemLinkLabel>
                                    {collection.description}
                                  </S.HorizontalMenuMobileItemLinkLabel>
                                  <S.HorizontalMenuSubmenuItemLinkQty>
                                    {collection.productsCount}
                                  </S.HorizontalMenuSubmenuItemLinkQty>
                                </S.HorizontalMenuMobileItemLink>
                                {showVariationsMobile === collection.id &&
                                  <S.HorizontalMenuMobileList showContent={showVariationsMobile}>
                                    <S.HorizontalMenuMobileSubmenuItem key={0}>
                                      <S.HorizontalMenuMobileSubmenuItemLink to={`/collection/${collection.id}`} state={{ collectionId: collection.id }} onClick={() => handleResetPage()}>
                                        <S.HorizontalMenuMobileSubmenuItemLinkLabel>
                                          Ver todos os produtos
                                        </S.HorizontalMenuMobileSubmenuItemLinkLabel>
                                      </S.HorizontalMenuMobileSubmenuItemLink>
                                    </S.HorizontalMenuMobileSubmenuItem>
                                    {collectionVariations.length > 0 && showVariationsMobile === collection.id &&
                                      collectionVariations.map((collectionVariation) => {
                                        if (collection.id === collectionVariation.collectionId) {
                                          return (
                                            collectionVariation.variations.map((variation) => {
                                              return (
                                                <S.HorizontalMenuMobileSubmenuItem key={variation.id}>
                                                  <S.HorizontalMenuMobileSubmenuItemLink to={`/collection/${collection.id}/variation/${variation.id}`} state={{ variationId: variation.id }} onClick={() => handleResetPage()}>
                                                    <S.HorizontalMenuMobileSubmenuItemLinkLabel>
                                                      {variation.description}
                                                    </S.HorizontalMenuMobileSubmenuItemLinkLabel>
                                                  </S.HorizontalMenuMobileSubmenuItemLink>
                                                </S.HorizontalMenuMobileSubmenuItem>
                                              )
                                            })
                                          )
                                        }
                                        return null;
                                      })
                                    }
                                  </S.HorizontalMenuMobileList>}
                              </S.HorizontalMenuMobileItem>
                            ))
                          }
                          <S.HorizontalMenuMobileDivider />
                        </S.HorizontalMenuMobileList>
                      </>
                    )}
                </>
              }
              {family.length > 8 && portalMenuType !== 2 &&
                <>
                  <S.HorizontalMenuMobileTitle onClick={() => setShowAllCategoriesMobile(showAllCategoriesMobile === true ? false : true)}>
                    Todas as categorias
                    {showAllCategoriesMobile ?  <S.StyledFontAwesomeIcon icon={faChevronUp} /> :  <S.StyledFontAwesomeIcon icon={faChevronDown} />}
                  </S.HorizontalMenuMobileTitle>
                    {showAllCategoriesMobile && (
                      <>
                        <S.HorizontalMenuMobileList showContent={showAllCategoriesMobile}>
                          {family.map(item => (
                            <S.HorizontalMenuMobileItem key={item.id}>
                              <S.HorizontalMenuMobileItemLink to='#' state={{ familyId: item.id }} onClick={() => { setShowSubcategoriesMobile(showSubcategoriesMobile !== item.id ? item.id : null); setIsCategoryOpen(true) }}>
                                <S.HorizontalMenuMobileItemLinkLabel>
                                  {item.description}
                                </S.HorizontalMenuMobileItemLinkLabel>
                                <S.HorizontalMenuSubmenuItemLinkQty>
                                  {item.productsCount}
                                </S.HorizontalMenuSubmenuItemLinkQty>
                              </S.HorizontalMenuMobileItemLink>
                              {showSubcategoriesMobile === item.id &&
                                <S.HorizontalMenuMobileList showContent={showSubcategoriesMobile}>
                                  <S.HorizontalMenuMobileSubmenuItem key={0}>
                                    <S.HorizontalMenuMobileSubmenuItemLink to={`/category/${item.id}/subcategory/0`} state={{ familyId: item.id }} onClick={() => handleResetPage()}>
                                      <S.HorizontalMenuMobileSubmenuItemLinkLabel>
                                        Ver todos os produtos
                                      </S.HorizontalMenuMobileSubmenuItemLinkLabel>
                                    </S.HorizontalMenuMobileSubmenuItemLink>
                                  </S.HorizontalMenuMobileSubmenuItem>
                                  {item.subcategory.map((subCat) => {
                                    return (
                                      <S.HorizontalMenuMobileSubmenuItem key={subCat.id}>
                                        <S.HorizontalMenuMobileSubmenuItemLink to={`/category/${item.id}/subcategory/${subCat.id}`} state={{ subcategoryId: subCat.id }} onClick={() => handleResetPage()}>
                                          <S.HorizontalMenuMobileSubmenuItemLinkLabel>
                                            {subCat.description}
                                          </S.HorizontalMenuMobileSubmenuItemLinkLabel>
                                          <S.HorizontalMenuSubmenuItemLinkQty>
                                            {subCat.productsCount}
                                          </S.HorizontalMenuSubmenuItemLinkQty>
                                        </S.HorizontalMenuMobileSubmenuItemLink>
                                      </S.HorizontalMenuMobileSubmenuItem>
                                    )
                                  })}
                                </S.HorizontalMenuMobileList>}
                            </S.HorizontalMenuMobileItem>
                          ))}
                          <S.HorizontalMenuMobileDivider />
                        </S.HorizontalMenuMobileList>
                      </>
                    )}
                </>
              }

              {portalMenuType !== 2 && menuOptions.length > 0 && menuOptions.map(item => (
                <S.HorizontalMenuMobileItem key={item.id}>
                  <S.HorizontalMenuMobileItemLink to='#' state={{ familyId: item.id }} onClick={() => { setFamilySelectedMobile(familySelectedMobile !== item.id ? item.id : null), setIsCategoryOpen(true) }}>
                    <S.HorizontalMenuMobileItemLinkLabel>
                      {item.description}
                    </S.HorizontalMenuMobileItemLinkLabel>
                    <S.HorizontalMenuSubmenuItemLinkQty>
                      {item.productsCount}
                    </S.HorizontalMenuSubmenuItemLinkQty>
                  </S.HorizontalMenuMobileItemLink>
                  {familySelectedMobile === item.id &&
                    <S.HorizontalMenuMobileList showContent={familySelectedMobile}>
                      <S.HorizontalMenuMobileSubmenuItem key={0}>
                        <S.HorizontalMenuMobileSubmenuItemLink to={`/category/${item.id}/subcategory/0`} state={{ familyId: item.id }} onClick={() => handleResetPage()}>
                          <S.HorizontalMenuMobileSubmenuItemLinkLabel>
                            Ver todos os produtos
                          </S.HorizontalMenuMobileSubmenuItemLinkLabel>
                        </S.HorizontalMenuMobileSubmenuItemLink>
                      </S.HorizontalMenuMobileSubmenuItem>
                      {item.subcategory.map((subCat) => {
                        return (
                          <S.HorizontalMenuMobileSubmenuItem key={subCat.id}>
                            <S.HorizontalMenuMobileSubmenuItemLink to={`/category/${item.id}/subcategory/${subCat.id}`} state={{ subcategoryId: subCat.id }} onClick={() => handleResetPage()}>
                              <S.HorizontalMenuMobileSubmenuItemLinkLabel>
                                {subCat.description}
                              </S.HorizontalMenuMobileSubmenuItemLinkLabel>
                              <S.HorizontalMenuSubmenuItemLinkQty>
                                {subCat.productsCount}
                              </S.HorizontalMenuSubmenuItemLinkQty>
                            </S.HorizontalMenuMobileSubmenuItemLink>
                          </S.HorizontalMenuMobileSubmenuItem>
                        )
                      })}
                    </S.HorizontalMenuMobileList>}
                </S.HorizontalMenuMobileItem>
              ))}
            </S.HorizontalMenuMobileCntDrop>
          </S.HorizontalMenuMobile>
        </> 
        :
        <>
          <S.HozizontalMenuWrapper>
            <S.HorizontalMenuNav ref={innerBorderRef}>
              <S.HorizontalMenuList>
                {/* Monta as opções das Coleções */}
                {(portalMenuType === 2 || portalMenuType === 3) && collection.length > 0 &&
                  <S.HorizontalMenuItem>
                    <S.HorizontalMenuItemLink to='#' onMouseEnter={() => {setHovered('collections'); setShowMainMenuSubcategories(null)}} $customHeaderColor={customHeaderColor} $hovered={hovered === 'collections'}>
                      <S.HorizontalMenuItemLinkLabel $hovered={hovered === 'collections'}>
                        Coleções
                      </S.HorizontalMenuItemLinkLabel>
                    </S.HorizontalMenuItemLink>
                    {hovered === 'collections' && (
                      <S.HorizontalMenuSubmenuList onMouseLeave={() => {showVariations === null && setHovered(null)}}>
                        {collection.map((collection) => {
                          return (
                            <S.HorizontalMenuSubmenuItem key={collection.id}>
                              <S.HorizontalMenuSubmenuItemLink to={'#'} state={{ collectionId: collection.id }} onClick={() => {setShowVariations(showVariations === collection.id ? null : collection.id)}}>
                                <S.HorizontalMenuSubmenuItemLinkLabel $bold={true}>
                                  {collection.description}
                                </S.HorizontalMenuSubmenuItemLinkLabel>
                                <S.HorizontalMenuSubmenuItemLinkQty>
                                  {collection.productsCount}
                                </S.HorizontalMenuSubmenuItemLinkQty>
                              </S.HorizontalMenuSubmenuItemLink>
                              {collectionVariations.length > 0 && showVariations === collection.id &&
                                collectionVariations.map((collectionVariation) => {
                                  if (collection.id === collectionVariation.collectionId) {
                                    return (
                                      <S.HorizontalMenuVariationList key={collectionVariation.collectionId}>
                                        <S.HorizontalMenuSubmenuItem>
                                          <S.HorizontalMenuVariationItemLink to={`/collection/${collection.id}`} state={{ collectionId: collection.id }} onClick={() => handleResetPage()}>
                                            <S.HorizontalMenuSubmenuItemLinkLabel $margin={true}>
                                              Ver todos os produtos
                                            </S.HorizontalMenuSubmenuItemLinkLabel>
                                          </S.HorizontalMenuVariationItemLink>
                                        </S.HorizontalMenuSubmenuItem>
                                        {collectionVariation.variations.map((variation) => {
                                          return (
                                            <S.HorizontalMenuSubmenuItem key={variation.id}>
                                              <S.HorizontalMenuVariationItemLink to={`/collection/${collection.id}/variation/${variation.id}`} state={{ variationId: variation.id }} onClick={() => handleResetPage()}>
                                                <S.HorizontalMenuSubmenuItemLinkLabel $margin={true}>
                                                  {variation.description}
                                                </S.HorizontalMenuSubmenuItemLinkLabel>
                                              </S.HorizontalMenuVariationItemLink>
                                            </S.HorizontalMenuSubmenuItem>
                                          )
                                        })}
                                      </S.HorizontalMenuVariationList>
                                    )
                                  }
                                  return null;
                                })
                              }
                            </S.HorizontalMenuSubmenuItem>
                          )
                        })}
                      </S.HorizontalMenuSubmenuList>
                    )}
                  </S.HorizontalMenuItem>
                }

                {/* Monta as opções das Categorias restantes */}
                {family.length > 8 && portalMenuType !== 2 &&
                  <S.HorizontalMenuItem>
                    <S.HorizontalMenuItemLink to='#' onMouseEnter={() => {setHovered('allCategories'); setShowMainMenuSubcategories(null)}} $customHeaderColor={customHeaderColor} $hovered={hovered === 'allCategories'}>
                      <S.HorizontalMenuItemLinkLabel $hovered={hovered === 'allCategories'}>
                        Todas as Categorias
                      </S.HorizontalMenuItemLinkLabel>
                    </S.HorizontalMenuItemLink>
                    {hovered === 'allCategories' && (
                      <S.HorizontalMenuSubmenuList onMouseLeave={() => {showSubcategories === null && setHovered(null)}}>
                        {allCategoriesOption.map((category) => {
                          return (
                            <S.HorizontalMenuSubmenuItem key={category.id}>
                              <S.HorizontalMenuSubmenuItemLink to={'#'} state={{ familyId: category.id }} onClick={() => {setShowSubcategories(showSubcategories === category.id ? null : category.id)}}>
                                <S.HorizontalMenuSubmenuItemLinkLabel $bold={true}>
                                  {category.description}
                                </S.HorizontalMenuSubmenuItemLinkLabel>
                                <S.HorizontalMenuSubmenuItemLinkQty>
                                  {category.productsCount}
                                </S.HorizontalMenuSubmenuItemLinkQty>
                              </S.HorizontalMenuSubmenuItemLink>
                              {showSubcategories === category.id &&
                                <S.HorizontalMenuVariationList>
                                  <S.HorizontalMenuSubmenuItem>
                                    <S.HorizontalMenuVariationItemLink to={`/category/${category.id}/subcategory/0`} state={{ familyId: category.id }} onClick={() => handleResetPage()}>
                                      <S.HorizontalMenuSubmenuItemLinkLabel $margin={true}>
                                        Ver todos os produtos
                                      </S.HorizontalMenuSubmenuItemLinkLabel>
                                    </S.HorizontalMenuVariationItemLink>
                                  </S.HorizontalMenuSubmenuItem>
                                  {category.subcategory.map((subcategory) => {
                                    return (
                                        <S.HorizontalMenuSubmenuItem key={subcategory.id}>
                                          <S.HorizontalMenuVariationItemLink to={`/category/${category.id}/subcategory/${subcategory.id}`} state={{ subcategoryId: subcategory.id }} onClick={() => handleResetPage()}>
                                            <S.HorizontalMenuSubmenuItemLinkLabel $margin={true}>
                                              {subcategory.description}
                                            </S.HorizontalMenuSubmenuItemLinkLabel>
                                          </S.HorizontalMenuVariationItemLink>
                                        </S.HorizontalMenuSubmenuItem>
                                    )
                                  })}
                                </S.HorizontalMenuVariationList>
                              }
                            </S.HorizontalMenuSubmenuItem>
                          )
                        })}
                      </S.HorizontalMenuSubmenuList>
                    )}
                  </S.HorizontalMenuItem>
                }

                {/* Monta as opções das configurações de ambiente */}
                {portalMenuType !== 2 && menuOptions.length > 0 && menuOptions.map((item, index) => (
                  <S.HorizontalMenuItem key={item.id}>
                    <S.HorizontalMenuItemLink 
                    to={`/category/${item.id}/subcategory/0`} state={{ familyId: item.id }} 
                    $hovered={hovered === index} $customHeaderColor={customHeaderColor}
                    onClick={() => { setShowMainMenuSubcategories(showMainMenuSubcategories !== item.id ? item.id : null), handleResetPage() }}
                    onMouseEnter={() => {setShowMainMenuSubcategories(item.id); setHovered(index)}}
                    onMouseLeave={() => {item.subcategory.length === 0 && setHovered(null)}}
                  >
                      <S.HorizontalMenuItemLinkLabel $hovered={hovered === index}>
                        {item.description}
                      </S.HorizontalMenuItemLinkLabel>
                    </S.HorizontalMenuItemLink>

                    {showMainMenuSubcategories === item.id && item.subcategory.length > 0 &&
                      <S.HorizontalMenuSubmenuList onMouseLeave={() => {setShowMainMenuSubcategories(null); setHovered(null)}}>
                        {item.subcategory.map((subCat) => {
                          return (
                            <S.HorizontalMenuSubmenuItem key={subCat.id}>
                              <S.HorizontalMenuSubmenuItemLink 
                              to={`/category/${item.id}/subcategory/${subCat.id}`} 
                              state={{ subcategoryId: subCat.id }} 
                              onClick={() => handleResetPage()}
                              >
                                <S.HorizontalMenuSubmenuItemLinkLabel>
                                  {subCat.description}
                                </S.HorizontalMenuSubmenuItemLinkLabel>
                                <S.HorizontalMenuSubmenuItemLinkQty>
                                  {subCat.productsCount}
                                </S.HorizontalMenuSubmenuItemLinkQty>
                              </S.HorizontalMenuSubmenuItemLink>
                            </S.HorizontalMenuSubmenuItem>
                          )
                        })}
                      </S.HorizontalMenuSubmenuList>
                    }
                  </S.HorizontalMenuItem>
                ))}

                <Filter
                  category={family}
                  isHorizontalMenu={true}
                  changeHeaderColor={changeHeaderColor}
                  closeOtherMenuHoritontalOptions={() => {setShowMainMenuSubcategories(null); setHovered(null)}}
                />
              </S.HorizontalMenuList>
            </S.HorizontalMenuNav>
          </S.HozizontalMenuWrapper>
        </>
      )}
    </>
  )
}

export default HorizontalMenu
